<template>
    <!-- 退出答题提示 -->
    <van-dialog v-model="show" title="" :showConfirmButton="false">
        <van-icon name="cross" @click="close" />
        <div class="tp">
            <img src="@/assets/img/submitPrompt1.png" alt="">
        </div>
        <p>未答题目:<span style="color:#F53838; margin:0 10px;">{{noAnswer}}</span>题</p>
        <p>已答题目:<span style="color:#2B8DF0; margin:0 10px;">{{qsTotalNum-noAnswer}}</span>题</p>
        <p class="clstips" v-if="noAnswer != 0" style="padding: 0 15px;">请继续作答，未答题目的标题已为您标志<span style="color:red;">红色</span></p>
        <div class="footer" style="padding: 0 15px;">
            <van-button type="info" :disabled="noAnswer != 0 || isDisable" @click="submit">确认提交</van-button>
            <van-button type="default" :disabled="isDisable" @click="goOn">继续答题</van-button>
        </div>
    </van-dialog>
</template>

<script>
import { Toast } from 'vant';
import { saveIvQtOptionList } from "@api/wxzp.js";
export default {
    props:{
        noAnswer:{
            type:Number,
            default:0
        },
        qsTotalNum:{
            type:Number,
            default:0
        },
        qsTiKu:{
            type:Array,
            qsTiKu:[]
        },
        rsid:{
            type:Number,
            default:0
        },
        paperid:{
            type:Number,
            default:0
        }
    },
    data() {
        return {
            show: false,
            isDisable:false
        };
    },
    methods:{
        close(){
            this.show = false;
        },
        submit(){
            console.log(this.qsTiKu,'qsTiKuqsTiKu');
            let submitData = [];
            this.qsTiKu.forEach((ele,idx) => {
                if(ele.type == "单选题"){
                    ele.opt.forEach((val,key) =>{
                        let obj = {};
                        obj.autoid = val.autoid;
                        obj.opres = val.answer;
                        submitData.push(obj)
                    })
                }else if(ele.type == "多选题"){
                    ele.opt.forEach((val,key) =>{
                        let obj = {};
                        obj.autoid = val.autoid;
                        obj.opres = val.answer.join(",");
                        submitData.push(obj)
                    })
                }else if(ele.type == "简答题"){
                    ele.opt.forEach((val,key) =>{
                        let obj = {};
                        obj.autoid = val.autoid;
                        obj.opres = val.answer;
                        submitData.push(obj)
                    })
                }else if(ele.type == "打分题"){
                    ele.opt.forEach((val,key) =>{
                        let obj = {};
                        obj.autoid = val.autoid;
                        obj.opres = val.answer;
                        submitData.push(obj)
                    })
                }
            });
            let submitObj = {
                rsid:this.rsid,
                paperid:this.paperid,
                data:submitData
            }
            saveIvQtOptionList(submitObj).then(res =>{
                console.log(res,'submitObjsubmitObj')
                if(res.iserror == 0){
                    Toast("提交成功");
                    this.isDisable = true;
                }
            })
            // this.$router.push({path:'/submitPage',query:{isAnswer:this.qsTotalNum-this.noAnswer,noAnswer:this.noAnswer}})
        },
        goOn(){
            this.show = false;
        },
    }
};
</script>

<style lang='less' scoped>
.van-dialog {
    width: 652px;
    height: 860px;
    font-family: Source Han Sans SC;
    overflow: visible;
    .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
    }

    /deep/.van-dialog__content {
        position: relative;
        padding-top: 300px;
        text-align: center;
        .tp{
            position: absolute;
            top:-240px;
            left:50%;
            transform: translateX(-50%);
            width:342px;
            height:608px;
            img{
                height:100%;
                width:100%;
            }
        }
        p{
            color:#333;
            font-size: 36px;
        }
        .footer{
            display: flex;
            flex-direction: column;
            align-items: center;
            .van-button{
                width:50%;
            }
            .van-button:first-child{
                margin-bottom: 24px;
            }
        }
    }

    .clstips{
        font-size: 28px !important;
    }
}
</style>
